import { Avatar, Box, Container, Grid, IconButton, Slide, Stack, Typography } from "@mui/material";
import SectionTitle from "../Title/SectionTitle";
import React from "react";
import { useInView } from "react-intersection-observer";

const cards = [
    {
        id: 1,
        title: "Sérgio Hoffmann",
        description: "Gerente de TI Donana",
        image: `${process.env.PUBLIC_URL}/assets/testimony/sergio.jpg`,
        testimony:
            "Nós da empresa Donana Alimentos estamos muito satisfeitos com o trabalho da empresa DevAll Tech. Sempre atenciosos e pacientes as nossas dúvidas e com muita agilidade e boa vontade na execução de tudo o que a eles foram solicitados. Recomendo, com certeza, a todas as empresas ou pessoas que buscam um trabalho sério e com prazo de entrega.",
    },
    {
        id: 4,
        title: "Décio Morais",
        description: "Gestor TI",
        image: `${process.env.PUBLIC_URL}/assets/testimony/decio.jpg`,
        testimony:
            "Gostaríamos de expressar nossa profunda gratidão à DevAll Tech pela excepcional expertise em integração de serviços e desenvolvimento web. Seu profissionalismo, comprometimento e entrega de soluções inovadoras foram fundamentais para o sucesso de nossos projetos. Agradecemos sinceramente pela parceria valiosa e pela qualidade exemplar dos serviços prestados.",
    },
    {
        id: 2,
        title: "Marcelo Sanches",
        description: "Diretor Clique Comida",
        image: `${process.env.PUBLIC_URL}/assets/testimony/marcelo.jpg`,
        testimony: "Pensando sempre na satisfação do cliente final.",
    },
    {
        id: 3,
        title: "Rodrigo Holanda",
        description: "Diretor Kanekria",
        image: `${process.env.PUBLIC_URL}/assets/testimony/rodrigo.jpg`,
        testimony: "Soluções práticas e acessíveis!",
    },
];

const HomeTestimony = () => {
    const [animation, setAnimation] = React.useState(false);

    const {
        ref: refView,
        inView,
        entry,
    } = useInView({
        threshold: 0.5,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    return (
        <div ref={refView}>
            <Box
                sx={{
                    backgroundImage: "linear-gradient(to right, #FAFAFA, #ECEFF1)",
                    width: "100%",
                    minHeight: "500px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                    <SectionTitle title="Depoimentos" subtitle="" />
                    <Slide direction="up" in={animation} mountOnEnter unmountOnExit>
                        <Grid container spacing={4} sx={{ mt: 2 }}>
                            {cards.map((card) => (
                                <Grid item key={`serv${card.id}`} xs={12} sm={6} /* md={4} */>
                                    <Stack direction="row" spacing={2}>
                                        <Avatar alt={card.title} src={card.image} sx={{ width: 72, height: 72 }} />
                                        <Box>
                                            <Typography variant="body1" fontWeight={600} sx={{ width: "100%", mt: 1 }}>
                                                {card.title}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                {card.description}
                                            </Typography>
                                            <Typography variant="body2" fontWeight={500}>
                                                {card.testimony}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Slide>
                </Container>
            </Box>
        </div>
    );
};

export default HomeTestimony;
