import { Alert, Avatar, Box, Button, Container, Slide, Snackbar, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";
import SectionTitle from "../Title/SectionTitle";
import { orange } from "@mui/material/colors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { AwsSesFunctions } from "../../core/functions/AwsSesFunctions";

const HomeContact = () => {
    const [animation, setAnimation] = React.useState(false);
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [sending, setSending] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const [error, setError] = React.useState("");
    const [validation, setValidation] = React.useState(true);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccess(false);
    };

    const { ref, inView, entry } = useInView({
        threshold: 0.7,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    const handleSendEmail = async () => {
        if (name.trim() === "" || email.trim() === "" || subject.trim() === "" || message.trim() === "") {
            setValidation(false);
            setError("Preencha os campos necessários!");
            return;
        }

        setValidation(true);

        try {
            setSending(true);
            await AwsSesFunctions.sendEmail({ name, email, subject, message });
            setSuccess(true);
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
        } finally {
            setSending(false);
        }
    };

    return (
        <div ref={ref}>
            <Box
                sx={{
                    backgroundColor: "white",
                    width: "100%",
                    minHeight: "500px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                    <SectionTitle
                        title="Contate-nos"
                        subtitle="Entre em contato com a gente e faça um orçamento sem compromisso ou deixe um comentário ou uma sugestão que gostaria de ver por aqui."
                    />
                    <Stack sx={{ mt: 5 }} direction={{ xs: "column", md: "row" }} spacing={3}>
                        <Slide direction="left" in={animation} mountOnEnter unmountOnExit>
                            <Stack spacing={2} width={{ xs: "100%", md: "60%" }}>
                                <TextField
                                    fullWidth
                                    id="contactName"
                                    label="Nome"
                                    placeholder="Digite aqui seu nome"
                                    variant="outlined"
                                    size="small"
                                    disabled={sending}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    error={!validation && name.trim() === ""}
                                    helperText={!validation && name.trim() === "" ? "Campo obrigatório" : ""}
                                />
                                <TextField
                                    fullWidth
                                    id="contactEmail"
                                    label="Email"
                                    placeholder="Digite aqui seu email"
                                    variant="outlined"
                                    size="small"
                                    disabled={sending}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!validation && email.trim() === ""}
                                    helperText={!validation && email.trim() === "" ? "Campo obrigatório" : ""}
                                />
                                <TextField
                                    fullWidth
                                    id="contactSubject"
                                    label="Assunto"
                                    placeholder="Digite o assunto"
                                    variant="outlined"
                                    size="small"
                                    disabled={sending}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    error={!validation && subject.trim() === ""}
                                    helperText={!validation && subject.trim() === "" ? "Campo obrigatório" : ""}
                                />
                                <TextField
                                    fullWidth
                                    multiline
                                    id="contactMessage"
                                    label="Mensagem"
                                    variant="outlined"
                                    size="small"
                                    minRows={4}
                                    disabled={sending}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    error={!validation && message.trim() === ""}
                                    helperText={!validation && message.trim() === "" ? "Campo obrigatório" : ""}
                                />
                                <Stack alignContent="center" alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={sending}
                                        onClick={handleSendEmail}
                                    >
                                        Enviar mensagem
                                    </Button>
                                </Stack>
                            </Stack>
                        </Slide>
                        <Slide direction="right" in={animation} mountOnEnter unmountOnExit>
                            <Stack direction={"column"} spacing={3} width={{ xs: "100%", md: "40%" }}>
                                <Stack direction={"row"} spacing={3} alignContent="center" alignItems="center">
                                    <Avatar sx={{ bgcolor: orange[500] }}>
                                        <LocationOnIcon sx={{ color: "white" }} />
                                    </Avatar>
                                    <Stack direction={"column"} spacing={1}>
                                        <Typography variant="body1">Rua Laurindo Costa, 40 Álvaro Campoy</Typography>
                                        <Typography variant="body1">Osvaldo Cruz / São Paulo - Brasil</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={"row"} spacing={3} alignContent="center" alignItems="center">
                                    <Avatar sx={{ bgcolor: orange[500] }}>
                                        <EmailIcon sx={{ color: "white" }} />
                                    </Avatar>
                                    <Stack direction={"column"} spacing={1}>
                                        <Typography variant="body1">contato@devalltech.com.br</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction={"row"} spacing={3} alignContent="center" alignItems="center">
                                    <Avatar sx={{ bgcolor: orange[500] }}>
                                        <PhoneIcon sx={{ color: "white" }} />
                                    </Avatar>
                                    <Stack direction={"column"} spacing={1}>
                                        <Typography variant="body1">+55 (18) 99792-2950</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Slide>
                    </Stack>
                </Container>
            </Box>

            <Snackbar open={error !== ""} autoHideDuration={6000} onClose={() => setError("")}>
                <Alert onClose={() => setError("")} severity="error" variant="filled" sx={{ width: "100%" }}>
                    {error}
                </Alert>
            </Snackbar>

            <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: "100%" }}>
                    E-mail enviado com sucesso!
                </Alert>
            </Snackbar>
        </div>
    );
};

export default HomeContact;
