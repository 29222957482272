import { Box, Container, Stack, Typography } from "@mui/material";

const HomeMethodology = () => {
    return (
        <Box
            sx={{
                backgroundColor: "white",
                width: "100%",
            }}
        >
            <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                <Stack sx={{ mt: 5 }} direction={{ xs: "column", md: "row" }} spacing={3}>
                    <Stack width={{ xs: "100%", md: "50%" }}>
                        <img src={`${process.env.PUBLIC_URL}/img/flutter.svg`} alt="flutter" />
                    </Stack>
                    <Stack direction={"column"} spacing={3}>
                        <Typography variant="h6">Metodologia</Typography>
                        <Typography variant="body1">
                            Utilizamos diversas metodologias para garantir a entrega de projetos de alta qualidade
                            dentro do prazo e orçamento estipulados pelos nossos clientes. Entre as metodologias que
                            utilizamos, destacam-se:
                        </Typography>
                        <Stack direction={"column"} spacing={1}>
                            <Typography variant="body2">
                                <b>Agile Scrum:</b> utilizamos o framework Scrum para gestão de projetos ágeis,
                                permitindo uma maior interação entre a equipe e o cliente, com entregas constantes e
                                acompanhamento da evolução do projeto em tempo real.
                            </Typography>
                            <Typography variant="body2">
                                <b>Design Thinking:</b> usamos o design thinking para entender as necessidades e
                                expectativas do cliente, analisar o mercado e desenvolver soluções inovadoras e
                                eficientes.
                            </Typography>
                            <Typography variant="body2">
                                <b>Lean Startup:</b> adotamos a metodologia Lean Startup para validar ideias e soluções
                                antes de investir tempo e recursos em projetos que podem não ter demanda ou não serem
                                viáveis no mercado.
                            </Typography>
                            <Typography variant="body2">
                                <b>DevOps:</b> utilizamos o DevOps para integrar o desenvolvimento e a operação de
                                software, garantindo maior agilidade, qualidade e segurança nos processos de entrega e
                                manutenção de sistemas.
                            </Typography>
                            <Typography variant="body2">
                                <b>Continuous Integration and Delivery (CI/CD):</b> implementamos a integração e entrega
                                contínuas para garantir a qualidade e rapidez na entrega de softwares.
                            </Typography>
                            <Typography variant="body2">
                                <b>User Experience (UX) Design:</b> aplicamos o UX Design para criar soluções que
                                atendam às necessidades e expectativas dos usuários, proporcionando uma experiência
                                agradável e eficiente.
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default HomeMethodology;
