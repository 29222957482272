import { Box, Container, Slide, Stack, Typography } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";

const HomeIdea = () => {
    const [animation, setAnimation] = React.useState(false);

    const { ref, inView, entry } = useInView({
        threshold: 0.7,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    return (
        <div ref={ref}>
            <Box
                sx={{
                    backgroundColor: "#E3F2FD",
                    width: "100%",
                    minHeight: "500px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                    <Stack sx={{ mt: 5 }} direction={{ xs: "column", md: "row" }} spacing={3}>
                        <Slide direction="right" in={animation} mountOnEnter unmountOnExit>
                            <Stack direction={"column"} spacing={3}>
                                <Typography variant="h6">A ideia</Typography>
                                <Typography variant="body1">
                                    Nosso principal desejo é desenvolver em tempo hábil e com custo benefício soluções
                                    tecnológicas de um problema ou necessidade do mundo real.
                                </Typography>
                                <Typography variant="body1">
                                    Se você está buscando uma empresa de confiança e que ofereça soluções digitais de
                                    qualidade para o seu negócio, a DevAll Tech é a escolha certa. Venha nos conhecer e
                                    transformar suas ideias em soluções digitais de sucesso!
                                </Typography>
                            </Stack>
                        </Slide>
                        <Slide direction="left" in={animation} mountOnEnter unmountOnExit>
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <img src={`${process.env.PUBLIC_URL}/img/react.svg`} alt="react" />
                            </Stack>
                        </Slide>
                    </Stack>
                </Container>
            </Box>
        </div>
    );
};

export default HomeIdea;
