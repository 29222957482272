import { Box, Container, Typography } from "@mui/material";

const ConscientizaPage = () => {
    return (
        <Container>
            <Box sx={{ py: 3 }}>
                <Typography variant="h3">Conscientiza</Typography>
                <br />
                <Typography variant="h5">Excluir conta de usuário</Typography>
                <br />
                <Typography>Para excluir definitivamente uma conta dentro do aplicativo é bem simples:</Typography>
                <Typography>
                    1. Após efetuar o login acessar o menu localizado no canto superior esquerdo com os 3 riscos.
                </Typography>
                <Typography>2. Acessar Configurações.</Typography>
                <Typography>3. Clicar no botão "Excluir definitivamente minha conta".</Typography>
                <br />
                <Typography>
                    Pronto, todas as suas informações de cadastros serão criptografadas e o usuário não poderá mais
                    reativar a conta, só criando uma nova conta podendo utilizar o mesmo e-mail já que o antigo cadastro
                    não se encontra mais em nossas informações.
                </Typography>
            </Box>
        </Container>
    );
};

export default ConscientizaPage;
