import { Container, Typography } from "@mui/material"

const CorpusTalkTermsPage = () => {
  return (
    <Container>
      <Typography variant="h3" gutterBottom textAlign="center">
        Termos de Uso e Privacidade
      </Typography>
      <Typography variant="h5" gutterBottom>
        Termos de Uso - Corpu's Talk
      </Typography>
      <br />
      <Typography textAlign="justify">
        Bem-vindo ao Corpu's Talk, uma plataforma de rede social focada em
        compartilhar fotos e vídeos de esportes. Antes de utilizar nossos
        serviços, por favor, leia atentamente os seguintes termos:
      </Typography>
      <br />
      <Typography textAlign="justify">
        1. <strong>Aceitação dos Termos</strong>: Ao acessar ou utilizar o
        Corpu's Talk, você concorda com estes Termos de Uso e com nossa Política
        de Privacidade. Se você não concorda com estes termos, por favor, não
        use nossos serviços.
      </Typography>
      <br />
      <Typography textAlign="justify">
        2. <strong>Uso dos Serviços</strong>: Você deve ter pelo menos 13 anos
        de idade para usar o Corpu's Talk. Ao utilizar nossos serviços, você
        concorda em respeitar todas as leis e regulamentos aplicáveis.
      </Typography>
      <br />
      <Typography textAlign="justify">
        3. <strong>Conteúdo do Usuário</strong>: Ao postar fotos e vídeos de
        esportes, você é responsável por garantir que possui os direitos
        necessários sobre esse conteúdo. Você concorda em não postar qualquer
        conteúdo que viole direitos autorais, marcas registradas, privacidade ou
        outros direitos de terceiros.
      </Typography>
      <br />
      <Typography textAlign="justify">
        4. <strong>Conduta do Usuário</strong>: Você concorda em não utilizar o
        Corpu's Talk para qualquer atividade ilegal, fraudulenta, difamatória,
        abusiva, obscena, discriminatória ou prejudicial de qualquer forma.
      </Typography>
      <br />
      <Typography textAlign="justify">
        5. <strong>Privacidade</strong>: Respeitamos a sua privacidade. Ao
        utilizar o Corpu's Talk, você concorda com nossa Política de
        Privacidade, que descreve como coletamos, usamos e compartilhamos suas
        informações.
      </Typography>
      <br />
      <Typography textAlign="justify">
        6. <strong>Propriedade Intelectual</strong>: Todos os direitos de
        propriedade intelectual relacionados ao Corpu's Talk pertencem à nossa
        empresa ou aos nossos licenciantes. Você concorda em não copiar,
        modificar, distribuir, transmitir, exibir, vender ou explorar qualquer
        parte do Corpu's Talk sem nossa autorização.
      </Typography>
      <br />
      <Typography textAlign="justify">
        7. <strong>Modificações nos Termos</strong>: Podemos revisar estes
        Termos de Uso periodicamente. A versão mais recente estará sempre
        disponível em nosso site. O uso contínuo do Corpu's Talk após as
        alterações significa que você concorda com os novos termos.
      </Typography>
      <br />
      <Typography textAlign="justify">
        8. <strong>Encerramento de Conta</strong>: Reservamo-nos o direito de
        encerrar sua conta ou restringir seu acesso ao Corpu's Talk a qualquer
        momento, por qualquer motivo, sem aviso prévio.
      </Typography>
      <br />
      <Typography textAlign="justify">
        9. <strong>Disposições Gerais</strong>: Estes Termos de Uso constituem o
        acordo completo entre você e o Corpu\'s Talk em relação ao seu uso dos
        nossos serviços.
      </Typography>
      <br />

      <Typography textAlign="justify">
        Se você tiver alguma dúvida ou preocupação sobre estes termos, entre em
        contato conosco em socialcorpustalk@gmail.com
      </Typography>
      <br />

      <Typography variant="h5" gutterBottom>
        Política de Privacidade - Corpu's Talk
      </Typography>
      <br />
      <Typography gutterBottom>
        Nossa prioridade é proteger a privacidade dos nossos usuários. Esta
        Política de Privacidade descreve como coletamos, usamos e protegemos
        suas informações pessoais ao usar o Corpu's Talk.
      </Typography>
      <br />
      <Typography textAlign="justify">
        1. <strong>Informações que Coletamos</strong>: Podemos coletar
        informações pessoais que você nos fornece ao se registrar, como nome,
        e-mail, data de nascimento e foto de perfil. Também podemos coletar
        informações sobre seu dispositivo, endereço IP e atividades no Corpu's
        Talk.
      </Typography>
      <br />
      <Typography textAlign="justify">
        2. <strong>Uso das Informações</strong>: Utilizamos suas informações
        pessoais para fornecer e melhorar nossos serviços, personalizar sua
        experiência, enviar comunicações relacionadas ao serviço e cumprir
        obrigações legais.
      </Typography>
      <br />
      <Typography textAlign="justify">
        3. <strong>Compartilhamento de Informações</strong>: Podemos
        compartilhar suas informações pessoais com prestadores de serviços
        terceirizados que nos ajudam a operar o Corpu\'s Talk. Não vendemos suas
        informações pessoais para terceiros.
      </Typography>
      <br />
      <Typography textAlign="justify">
        4. <strong>Segurança das Informações</strong>: Implementamos medidas de
        segurança para proteger suas informações pessoais contra acesso não
        autorizado, alteração, divulgação ou destruição.
      </Typography>
      <br />
      <Typography textAlign="justify">
        5. <strong>Cookies e Tecnologias Semelhantes</strong>: Podemos usar
        cookies e tecnologias semelhantes para coletar informações sobre como
        você interage com o Corpu\'s Talk e personalizar sua experiência.
      </Typography>
      <br />
      <Typography textAlign="justify">
        6. <strong>Menores de Idade</strong>: O Corpu\'s Talk não se destina a
        menores de 13 anos, e não coletamos intencionalmente informações
        pessoais de crianças menores de 13 anos.
      </Typography>
      <br />
      <Typography textAlign="justify">
        7. <strong>Alterações nesta Política</strong>: Podemos revisar esta
        Política de Privacidade periodicamente. A versão mais recente estará
        sempre disponível em nosso site.
      </Typography>
      <br />
      <Typography textAlign="justify">
        Se você tiver alguma dúvida ou preocupação sobre nossa Política de
        Privacidade, entre em contato conosco em socialcorpustalk@gmail.com
      </Typography>
    </Container>
  )
}

export default CorpusTalkTermsPage
