import { SES } from '@aws-sdk/client-ses';
import { getTemplateHTML } from '../../components/Templates/SimpleEmailTemplate';

const ses = new SES({ region: "sa-east-1", credentials: { accessKeyId: "AKIARAYTVGUNBYNVUO6Z", secretAccessKey: "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" } });

export interface IAwsSesSendEmail {
    name: string;
    email: string;
    subject: string;
    message: string;
}

const sendEmail = async ({ name, email, subject, message }: IAwsSesSendEmail) => {
    const emailHtml = getTemplateHTML({ name, email, subject, message });

    const params = {
        Source: 'contato@devalltech.com.br',
        Destination: {
            ToAddresses: ['contato@devalltech.com.br'],
        },
        Message: {
            Body: {
                Html: {
                    Charset: 'UTF-8',
                    Data: emailHtml,
                },
            },
            Subject: {
                Charset: 'UTF-8',
                Data: 'Site DevAll Tech',
            },
        },
    };

    await ses.sendEmail(params);
}

export const AwsSesFunctions = {
    sendEmail,
}