import { Avatar, Box, Container, Slide } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SectionTitle from "../Title/SectionTitle";
import React from "react";
import { useInView } from "react-intersection-observer";

const cards = [
    {
        id: 1,
        title: "Alan Chaves da Silva",
        image: `${process.env.PUBLIC_URL}/assets/clients/donana.png`,
    },
    {
        id: 2,
        title: "Ronison Vichi de Matos",
        image: `${process.env.PUBLIC_URL}/assets/clients/cliquecomida.png`,
    },
    {
        id: 3,
        title: "Diego Veríssimo Dutra",
        image: `${process.env.PUBLIC_URL}/assets/clients/knekria.png`,
    },
    {
        id: 4,
        title: "Yes Sinergy",
        image: `${process.env.PUBLIC_URL}/assets/clients/yes.png`,
    },
];

const HomeClient = () => {
    const [animation, setAnimation] = React.useState(false);

    const {
        ref: refView,
        inView,
        entry,
    } = useInView({
        threshold: 0.5,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    return (
        <div ref={refView}>
            <Box
                sx={{
                    backgroundColor: "#E3F2FD",
                    width: "100%",
                    minHeight: "400px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                    <SectionTitle title="Nossos Parceiros" subtitle="Alguns de nossos parceiros satisfeitos." />
                    <Slide direction="up" in={animation} mountOnEnter unmountOnExit>
                        <Grid
                            container
                            spacing={2}
                            columns={12}
                            sx={{ mt: 6 }}
                            alignContent="center"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {cards.map((card) => (
                                <Grid
                                    key={`serv${card.id}`}
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Avatar alt={card.title} src={card.image} sx={{ width: 180, height: 180 }} />
                                </Grid>
                            ))}
                        </Grid>
                    </Slide>
                </Container>
            </Box>
        </div>
    );
};

export default HomeClient;
