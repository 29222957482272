import { Avatar, Box, Container, Grid, IconButton, Slide, Stack, Typography } from "@mui/material";
import SectionTitle from "../Title/SectionTitle";
import React from "react";
import { useInView } from "react-intersection-observer";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

enum CardType {
    Twitter,
    Facebook,
    Instagram,
    LinkedIn,
}

const cards = [
    {
        id: 1,
        title: "Alan Chaves da Silva",
        description: "Diretor Executivo | Programador Sênior",
        image: `${process.env.PUBLIC_URL}/assets/team/alan.jpg`,
        socials: [
            {
                type: CardType.Twitter,
                url: "https://twitter.com/alanchavesdev",
            },
            {
                type: CardType.Facebook,
                url: "https://www.facebook.com/alan.chaves.50/",
            },
            {
                type: CardType.Instagram,
                url: "https://www.instagram.com/alanchaves_/",
            },
            {
                type: CardType.LinkedIn,
                url: "https://www.linkedin.com/in/alan-chaves-ab877a156/",
            },
        ],
    },
    {
        id: 2,
        title: "Ronison Vichi de Matos",
        description: "Designer | Programador Pleno",
        image: `${process.env.PUBLIC_URL}/assets/team/rony.jpg`,
        socials: [
            {
                type: CardType.Facebook,
                url: "https://www.facebook.com/ronison.matos.92",
            },
            {
                type: CardType.Instagram,
                url: "https://www.instagram.com/ronison.vmatos/",
            },
            {
                type: CardType.LinkedIn,
                url: "https://www.linkedin.com/in/ronison-matos/",
            },
        ],
    },
    {
        id: 3,
        title: "Diego Veríssimo Dutra",
        description: "Programador Junior",
        image: `${process.env.PUBLIC_URL}/assets/team/diego.jpeg`,
        socials: [
            {
                type: CardType.Facebook,
                url: "https://www.facebook.com/diego.dutra.9",
            },
            {
                type: CardType.Instagram,
                url: "https://www.instagram.com/diego_dutra01/",
            },
            {
                type: CardType.LinkedIn,
                url: "https://www.linkedin.com/in/diego-dutra-5b589223b/",
            },
        ],
    },
];

const HomeTeam = () => {
    const [animation, setAnimation] = React.useState(false);

    const {
        ref: refView,
        inView,
        entry,
    } = useInView({
        threshold: 0.5,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    const openNewBlank = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    const getIcon = (cardType: CardType) => {
        switch (cardType) {
            case CardType.Twitter:
                return <XIcon />;
            case CardType.Facebook:
                return <FacebookIcon />;
            case CardType.Instagram:
                return <InstagramIcon />;
            case CardType.LinkedIn:
                return <LinkedInIcon />;
        }
    };

    return (
        <div ref={refView}>
            <Box
                sx={{
                    backgroundColor: "white",
                    // backgroundImage: "linear-gradient(to right, #FAFAFA, #ECEFF1)",
                    width: "100%",
                    minHeight: "500px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                    <SectionTitle title="Nossa Equipe" subtitle="Focados, comprometidos e experientes." />
                    <Slide direction="up" in={animation} mountOnEnter unmountOnExit>
                        <Grid container spacing={4} sx={{ mt: 2 }}>
                            {cards.map((card) => (
                                <Grid item key={`serv${card.id}`} xs={12} sm={6} md={4}>
                                    <Box sx={{ p: 4 }} alignContent="center" alignItems="center">
                                        <Avatar
                                            alt={card.title}
                                            src={card.image}
                                            sx={{ width: "100%", height: "100%" }}
                                        />
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={500}
                                            textAlign="center"
                                            sx={{ width: "100%", mt: 1 }}
                                        >
                                            {card.title}
                                        </Typography>
                                        <Typography variant="body2" textAlign="center" sx={{ width: "100%" }}>
                                            {card.description}
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignContent="center"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {card.socials.map((social) => (
                                                <IconButton
                                                    key={social.url}
                                                    size="small"
                                                    onClick={() => openNewBlank(social.url)}
                                                >
                                                    {getIcon(social.type)}
                                                </IconButton>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Slide>
                </Container>
            </Box>
        </div>
    );
};

export default HomeTeam;
