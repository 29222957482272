import * as React from "react";

export interface ISimpleEmailTemplateProps {
    name: string;
    email: string;
    subject: string;
    message: string;
}

export const getTemplateHTML = ({ name, email, subject, message }: ISimpleEmailTemplateProps) => {
    return `
        <html lang="pt-br">
            <body>
                <div>
                    <b>Nome: </b> ${name}
                </div>
                <div>
                    <b>E-mail: </b> ${email}
                </div>
                <div>
                    <b>Assunto: </b> ${subject}
                </div>
                <b>Mensagem: </b>
                <br />
                ${message}
            </body>
        </html>
    `;
};
