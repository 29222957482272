import { Box, Button, Container, Fade, Slide, Stack, Typography } from "@mui/material";
import React from "react";

export interface IHomeTopProp {
    animation: boolean;
    handleAbount: () => void;
    handleContact: () => void;
}

const HomeTop = ({ animation, handleAbount, handleContact }: IHomeTopProp) => {
    return (
        <Box
            sx={{
                backgroundImage: "linear-gradient(to right, #1976D2, #0D47A1)",
                backdropFilter: "blur(8px)",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100%",
                minHeight: "700px",
            }}
        >
            <Box sx={{ width: "100%", position: "relative" }}>
                <Slide direction="right" in={animation} mountOnEnter unmountOnExit>
                    <Box
                        height="900px"
                        width="900px"
                        sx={{
                            position: "absolute",
                            left: "calc(100% - 900px)",
                            top: -100,
                            filter: "blur(1.2rem)",
                            display: { xs: "none", md: "block" },
                            alignContent: "start",
                            alignItems: "start",
                            justifyContent: "start",
                        }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/img/logo-colors.png`}
                            alt="logo-web"
                            style={{
                                height: "900px",
                                width: "900px",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                </Slide>
                <Container sx={{ p: { xs: 2, md: 10, position: "relative" } }}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
                        <Stack spacing={3}>
                            <Box sx={{ display: { xs: "none", sm: "block" } }}>
                                <Fade in={animation} mountOnEnter unmountOnExit>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/img/logo-web.png`}
                                        alt="logo-web"
                                        width="60%"
                                    />
                                </Fade>
                            </Box>
                            <Box sx={{ p: 2, display: { xs: "block", sm: "none" } }}>
                                <Fade in={animation} mountOnEnter unmountOnExit>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/img/logo-web.png`}
                                        alt="logo-web"
                                        width="90%"
                                    />
                                </Fade>
                            </Box>
                            <Fade in={animation} mountOnEnter unmountOnExit>
                                <Typography variant="h3" sx={{ color: "white" }}>
                                    Transformamos ideias em soluções digitais de sucesso!
                                </Typography>
                            </Fade>
                            <Fade in={animation} mountOnEnter unmountOnExit>
                                <Box>
                                    <Button variant="contained" color="secondary" onClick={handleAbount}>
                                        Sobre nós
                                    </Button>
                                    <Button variant="outlined" color="secondary" sx={{ ml: 2 }} onClick={handleContact}>
                                        Entrar em contato
                                    </Button>
                                </Box>
                            </Fade>
                        </Stack>
                        <Slide direction="left" in={animation} mountOnEnter unmountOnExit>
                            <Stack width={{ xs: "100%", md: "50%" }}>
                                <img src={`${process.env.PUBLIC_URL}/img/top.svg`} alt="top" />
                            </Stack>
                        </Slide>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default HomeTop;
