import { Typography } from "@mui/material";

export interface ISectionTitleProp {
    title: string;
    subtitle?: string;
}

const SectionTitle = ({ title, subtitle }: ISectionTitleProp) => {
    return (
        <>
            <Typography gutterBottom variant="h4" sx={{ textAlign: "center" }}>
                {title}
            </Typography>
            {subtitle && (
                <Typography variant="subtitle1" sx={{ textAlign: "center", mx: { xs: 0, sm: 10, md: 20 } }}>
                    {subtitle}
                </Typography>
            )}
        </>
    );
};

export default SectionTitle;
