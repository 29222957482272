import { Avatar, Box, Container, Slide, Stack, Typography } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { blue, orange } from "@mui/material/colors";
import HostFunctions from "../../core/functions/HostFuncions";

const HomeFoother = () => {
    const [animation, setAnimation] = React.useState(false);

    const { ref, inView, entry } = useInView({
        threshold: 0.7,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    return (
        <div ref={ref}>
            <Box
                sx={{
                    backgroundColor: "#1565C0",
                    width: "100%",
                    minHeight: "300px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 3 }}>
                    <Stack sx={{ mt: 5 }} direction={{ xs: "column", md: "row" }} spacing={3}>
                        <Stack direction={"column"} spacing={3} width={{ xs: "100%", md: "33%" }}>
                            <Typography variant="h4" color="white">
                                DEVALL TECH
                            </Typography>
                            <Typography variant="body2" color="white">
                                Especializada em desenvolvimento de aplicativos, concentra as informações do seu negócio
                                em um único lugar, tornando ele acessível de qualquer ponto que contenha internet, tudo
                                isso com qualidade e segurança. Consultoria em aplicativos, sistemas e bancos de dados
                                já existentes.
                            </Typography>
                        </Stack>
                        <Stack width={{ xs: "100%", md: "33%" }} alignContent="center" alignItems="center">
                            <img
                                src={`${process.env.PUBLIC_URL}/img/logo-colors.png`}
                                alt="logo-colors"
                                height={212}
                                width={212}
                            />
                        </Stack>
                        <Stack width={{ xs: "100%", md: "33%" }}>
                            <Typography gutterBottom variant="h6" color="white" sx={{ mb: 3 }}>
                                CONTATE-NOS
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Avatar
                                    sx={{
                                        bgcolor: orange[500],
                                        width: 36,
                                        height: 36,
                                        cursor: "pointer",
                                        ":hover": { bgcolor: blue[900] },
                                    }}
                                    onClick={() => HostFunctions.openNewBlank("https://twitter.com/DevallTech")}
                                >
                                    <XIcon sx={{ color: "white" }} />
                                </Avatar>
                                <Avatar
                                    sx={{
                                        bgcolor: orange[500],
                                        width: 36,
                                        height: 36,
                                        cursor: "pointer",
                                        ":hover": { bgcolor: blue[900] },
                                    }}
                                    onClick={() => HostFunctions.openNewBlank("https://www.facebook.com/devalltech")}
                                >
                                    <FacebookIcon sx={{ color: "white" }} />
                                </Avatar>
                                <Avatar
                                    sx={{
                                        bgcolor: orange[500],
                                        width: 36,
                                        height: 36,
                                        cursor: "pointer",
                                        ":hover": { bgcolor: blue[900] },
                                    }}
                                    onClick={() => HostFunctions.openNewBlank("https://www.instagram.com/devall.tech/")}
                                >
                                    <InstagramIcon sx={{ color: "white" }} />
                                </Avatar>
                                <Avatar
                                    sx={{
                                        bgcolor: orange[500],
                                        width: 36,
                                        height: 36,
                                        cursor: "pointer",
                                        ":hover": { bgcolor: blue[900] },
                                    }}
                                    onClick={() =>
                                        HostFunctions.openNewBlank("https://www.linkedin.com/company/devall-tech/")
                                    }
                                >
                                    <LinkedInIcon sx={{ color: "white" }} />
                                </Avatar>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#0D47A1",
                    width: "100%",
                    minHeight: "80px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 3 }}>
                    <Typography variant="subtitle2" color="white" textAlign="center">
                        © Copyright DevAll Tech. Todos os direitos reservados
                    </Typography>
                    <Typography variant="body2" color="white" textAlign="center">
                        Projetado por{" "}
                        <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                            DevAll Tech
                        </Link>
                    </Typography>
                </Container>
            </Box>
        </div>
    );
};

export default HomeFoother;
