import { Verified, Visibility, Work } from "@mui/icons-material";
import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import SectionTitle from "../Title/SectionTitle";

const HomeAbount = () => {
    return (
        <Box
            sx={{
                backgroundColor: "white",
                width: "100%",
            }}
        >
            <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                <SectionTitle
                    title="Sobre nós"
                    subtitle="Conheça um pouco mais sobre a empresa e algumas das soluções oferecidas."
                />
                <Stack sx={{ mt: 5 }} direction={{ xs: "column", md: "row" }} spacing={3}>
                    <Stack width={{ xs: "100%", md: "50%" }}>
                        <img src={`${process.env.PUBLIC_URL}/img/team-up.svg`} alt="team-up" />
                    </Stack>
                    <Stack direction={"column"} spacing={3}>
                        <Typography variant="body1">
                            A DevAll Tech é uma empresa de desenvolvimento de aplicativos, softwares e sites que tem
                            como objetivo transformar ideias em soluções digitais de sucesso. Com uma equipe altamente
                            qualificada e experiente, oferecemos serviços de qualidade que atendem às necessidades de
                            nossos clientes, sempre buscando a inovação e a atualização constante em tecnologia.
                        </Typography>
                        <Stack direction={"row"} spacing={3}>
                            <Avatar sx={{ bgcolor: orange[500] }}>
                                <Work sx={{ color: "white" }} />
                            </Avatar>
                            <Stack direction={"column"} spacing={1}>
                                <Typography variant="h6">Missão</Typography>
                                <Typography variant="body1">
                                    Ajudar nossos clientes a alcançarem seus objetivos por meio de soluções digitais
                                    inovadoras, de qualidade e personalizadas. Buscamos sempre entender as necessidades
                                    de nossos clientes e fornecer uma solução que atenda às suas expectativas, gerando
                                    valor para o seu negócio.
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack direction={"row"} spacing={3}>
                            <Avatar sx={{ bgcolor: orange[500] }}>
                                <Visibility sx={{ color: "white" }} />
                            </Avatar>
                            <Stack direction={"column"} spacing={1}>
                                <Typography variant="h6">Visão</Typography>
                                <Typography variant="body1">
                                    Ser a principal referência no mercado de desenvolvimento de aplicativos, softwares e
                                    sites, oferecendo soluções inovadoras e de qualidade que atendam às necessidades de
                                    nossos clientes.
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack direction={"row"} spacing={3}>
                            <Avatar sx={{ bgcolor: orange[500] }}>
                                <Verified sx={{ color: "white" }} />
                            </Avatar>
                            <Stack direction={"column"} spacing={1}>
                                <Typography variant="h6">Valores</Typography>
                                <Typography variant="body1">
                                    Qualidade, inovação, comprometimento, transparência e trabalho em equipe.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default HomeAbount;
