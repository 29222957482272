import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Slide,
    Typography,
} from "@mui/material";
import SectionTitle from "../Title/SectionTitle";
import React from "react";
import { useInView } from "react-intersection-observer";

const cards = [
    {
        id: 1,
        title: "Desenvolvimento de projeto",
        description:
            "Ideal para projetos que serão desenvolvidos do início pela nossa equipe, sendo ele WEB, APP ou SOFTWARE.",
        image: `${process.env.PUBLIC_URL}/assets/services/project.jpg`,
    },
    {
        id: 2,
        title: "Desenvolvimento sob demanda",
        description:
            "Alterações em sistemas complexos ou até mesmo simples, com disponibilidade em desenvolvedores ou entegra.",
        image: `${process.env.PUBLIC_URL}/assets/services/dev.jpg`,
    },
    {
        id: 3,
        title: "Análise de desenvolvimento",
        description:
            "Gerenciamento de processos de desenvolvimento, análise de código fonte, performace e custo de vida do software.",
        //image: "https://source.unsplash.com/random?wallpapers",
        image: `${process.env.PUBLIC_URL}/assets/services/manager.jpg`,
    },
];

const HomeService = () => {
    const [animation, setAnimation] = React.useState(false);

    const {
        ref: refView,
        inView,
        entry,
    } = useInView({
        threshold: 0.5,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    return (
        <div ref={refView}>
            <Box
                sx={{
                    // backgroundColor: "#FFF3E0",
                    backgroundImage: "linear-gradient(to right, #FAFAFA, #ECEFF1)",
                    width: "100%",
                    minHeight: "500px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                    <SectionTitle
                        title="Serviços"
                        subtitle="Nossos serviços podem variar com base em nossa análise, entregando o valor esperado no melhor tempo, superando as espectativas."
                    />
                    <Slide direction="up" in={animation} mountOnEnter unmountOnExit>
                        <Grid container spacing={4} sx={{ mt: 2 }}>
                            {cards.map((card) => (
                                <Grid item key={`serv${card.id}`} xs={12} sm={6} md={4}>
                                    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                        <CardMedia
                                            component="div"
                                            sx={{
                                                // 16:9
                                                pt: "56.25%",
                                            }}
                                            image={card.image}
                                        />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {card.title}
                                            </Typography>
                                            <Typography>{card.description}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            {/* <Button size="small">Ver mais</Button> */}
                                            {/* <Button size="small">Edit</Button> */}
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Slide>
                </Container>
            </Box>
        </div>
    );
};

export default HomeService;
