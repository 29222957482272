import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";

export interface IHomeDrawerProp {
    navItems: string[];
    handleDrawerToggle: () => void;
    handleMenuChange: (item: string) => void;
}

const HomeDrawer = ({ navItems, handleDrawerToggle, handleMenuChange }: IHomeDrawerProp) => {
    return (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                DevAll Tech
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton onClick={() => handleMenuChange(item)} sx={{ textAlign: "start" }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default HomeDrawer;
