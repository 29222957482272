import { Box, Container, Fade, IconButton, ImageList, ImageListItem, ImageListItemBar, Slide } from "@mui/material";
import SectionTitle from "../Title/SectionTitle";
import React from "react";
import { useInView } from "react-intersection-observer";
import InfoIcon from "@mui/icons-material/Info";

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
}

const HomePortfolio = () => {
    const [animation, setAnimation] = React.useState(false);
    const [enterImg, setEnterImg] = React.useState("");

    const {
        ref: refView,
        inView,
        entry,
    } = useInView({
        threshold: 0.5,
        delay: 100,
        onChange: (inView, entry) => {
            if (inView && !animation) {
                setAnimation(true);
            }
        },
    });

    const handleMouseEnter = (item: any) => {
        setEnterImg(item.img);
    };

    const handleMouseLeave = () => {
        setEnterImg("");
    };

    return (
        <div ref={refView}>
            <Box
                sx={{
                    backgroundColor: "white",
                    width: "100%",
                    minHeight: "1024px",
                }}
            >
                <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
                    <SectionTitle
                        title="Nosso Portfólio"
                        subtitle="Contamos com um portfólio diversificado de projetos bem-sucedidos, desenvolvidos para empresas de diversos segmentos. Cada projeto é pensado de forma única, levando em conta as necessidades e especificidades do cliente, garantindo assim um resultado final que realmente atenda às expectativas e necessidades do negócio."
                    />
                    <Box sx={{ mt: 4 }}></Box>
                    <Slide direction="up" in={animation} mountOnEnter unmountOnExit>
                        <div>
                            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                                <ImageList variant="masonry" cols={3} rowHeight="auto">
                                    {itemData.map((item) => (
                                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                            <img
                                                {...srcset(item.img, 121, item.rows, item.cols)}
                                                alt={item.title}
                                                loading="lazy"
                                                onClick={() => handleMouseEnter(item)}
                                                onMouseEnter={() => handleMouseEnter(item)}
                                                onMouseLeave={handleMouseLeave}
                                            />
                                            <Fade in={enterImg === item.img} mountOnEnter unmountOnExit>
                                                <ImageListItemBar
                                                    title={item.title}
                                                    subtitle={item.type}
                                                    actionIcon={
                                                        <IconButton
                                                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                                            aria-label={`info about ${item.title}`}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    }
                                                    onMouseEnter={() => handleMouseEnter(item)}
                                                    onMouseLeave={handleMouseLeave}
                                                />
                                            </Fade>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            <Box sx={{ display: { xs: "none", sm: "block", md: "none" } }}>
                                <ImageList variant="masonry" cols={2} rowHeight="auto">
                                    {itemData.map((item) => (
                                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                            <img
                                                {...srcset(item.img, 121, item.rows, item.cols)}
                                                alt={item.title}
                                                loading="lazy"
                                                onClick={() => handleMouseEnter(item)}
                                                onMouseEnter={() => handleMouseEnter(item)}
                                                onMouseLeave={handleMouseLeave}
                                            />
                                            <Fade in={enterImg === item.img} mountOnEnter unmountOnExit>
                                                <ImageListItemBar
                                                    title={item.title}
                                                    subtitle={item.type}
                                                    actionIcon={
                                                        <IconButton
                                                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                                            aria-label={`info about ${item.title}`}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    }
                                                    onMouseEnter={() => handleMouseEnter(item)}
                                                    onMouseLeave={handleMouseLeave}
                                                />
                                            </Fade>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            <Box sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
                                <ImageList variant="masonry" cols={1} rowHeight="auto">
                                    {itemData.map((item) => (
                                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                            <img
                                                {...srcset(item.img, 121, item.rows, item.cols)}
                                                alt={item.title}
                                                loading="lazy"
                                                onClick={() => handleMouseEnter(item)}
                                                onMouseEnter={() => handleMouseEnter(item)}
                                                onMouseLeave={handleMouseLeave}
                                            />
                                            <Fade in={enterImg === item.img} mountOnEnter unmountOnExit>
                                                <ImageListItemBar
                                                    title={item.title}
                                                    subtitle={item.type}
                                                    actionIcon={
                                                        <IconButton
                                                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                                            aria-label={`info about ${item.title}`}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    }
                                                    onMouseEnter={() => handleMouseEnter(item)}
                                                    onMouseLeave={handleMouseLeave}
                                                />
                                            </Fade>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                        </div>
                    </Slide>
                </Container>
            </Box>
        </div>
    );
};

const itemData = [
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/icontrole.jpg`,
        title: "iControle",
        type: "APP",
        rows: 2,
        cols: 2,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/devall-web.jpg`,
        title: "DevAll Tech",
        type: "WEB",
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/economize.jpeg`,
        title: "Economize",
        type: "APP",
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/forcadevendas.jpg`,
        title: "Força de Vendas",
        type: "APP",
        cols: 2,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/cliquecomida.jpg`,
        title: "Clique Comida",
        type: "APP",
        cols: 2,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/syscourse.jpg`,
        title: "SysCourse",
        type: "SOFTWARE",
        rows: 2,
        cols: 2,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/yescarga.jpg`,
        title: "Yes Carga",
        type: "APP",
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/yescarga-software.jpg`,
        title: "Yes Carga",
        type: "SOFTWARE",
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/portfolio/conscientiza.jpeg`,
        title: "Conscientiza",
        type: "APP",
        rows: 2,
        cols: 2,
    },
];

export default HomePortfolio;
