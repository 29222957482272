import ConscientizaPage from "./screens/Conscientiza"
import CorpusTalkTermsPage from "./screens/CorpusTalk/Terms"
import EconomizePage from "./screens/Economize"
import HomePage from "./screens/Home"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "economize",
    element: <EconomizePage />,
  },
  {
    path: "conscientiza",
    element: <ConscientizaPage />,
  },
  {
    path: "corpustalk/terms",
    element: <CorpusTalkTermsPage />,
  },
])

export default function App(props: Props) {
  return <RouterProvider router={router} />
}
